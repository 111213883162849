<template>
  <form v-on:submit.prevent="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ isEdit ? 'Edit' : 'Add' }} User
      </h4>
    </div>

    <div class="modal-body">
      <loading :active.sync="isPageLoading"></loading>
      <template v-if="!isPageLoading">
        <div class="form-row">
          <div class="col-md-6 mb-3">
            <label for="userFirstName">First name <span class="red-dot ml-3"></span></label>
            <input id="userFirstName" name="userFirstName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('userFirstName') }"
              autocomplete="off" placeholder="Enter first name" v-validate="'required'" v-model="user.firstName" data-vv-as="First Name" />
            <div class="invalid-feedback">The First Name field is required.</div>
          </div>

          <div class="col-md-6 mb-3">
            <label for="userLastName">Last name <span class="red-dot ml-3"></span></label>
            <input id="userLastName" name="userLastName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('userLastName') }"
              autocomplete="off" placeholder="Enter Last name" v-validate="'required'" v-model="user.lastName" data-vv-as="Last Name" />
            <div class="invalid-feedback">The Last Name field is required.</div>
          </div>
        </div>

        <div class="form-group" v-if="isEdit === false">
          <label for="userEmail">Email <span class="red-dot ml-3"></span></label>
          <input id="userEmail" name="userEmail" type="text" class="form-control" :class="{ 'is-invalid': errors.has('userEmail') }"
            autocomplete="off" placeholder="Enter email address" v-validate="'required|email'" v-model="user.email" data-vv-as="Email" />
          <div class="invalid-feedback">{{ errors.first('userEmail') }}</div>
        </div>

        <div class="form-group" v-if="isEdit === true">
          <label for="userEmail">Email</label>
          <div class="col-sm-10">
            <input id="userEmail" name="userEmail" type="text"  class="form-control-plaintext" :value="user.email" readonly disabled />
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-6 mb-3">
            <label for="userPassword">Password <span class="red-dot ml-3"></span></label>
            <input id="userPassword" name="userPassword" type="password" class="form-control" :class="{ 'is-invalid': errors.has('userPassword') }"
              autocomplete="off" placeholder="Enter password" v-validate="{ required: isEdit == false, min:8, max:50 }" v-model="user.password"
              data-vv-as="Password" ref="password" />
            <div class="invalid-feedback">{{ errors.first('userPassword') }}</div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="userPasswordConfirm">Confirm Password <span class="red-dot ml-3"></span></label>
            <input id="userPasswordConfirm" name="userPasswordConfirm" type="password" class="form-control" :class="{ 'is-invalid': errors.has('userPasswordConfirm') }"
              autocomplete="off" placeholder="Enter Password Confirmation" v-validate="{ required: isPasswordConfirmRequired(), confirmed: 'password' }"
              data-vv-as="Confirm Password" />
            <div class="invalid-feedback">'Confirm Password' and 'Password' do not match.</div>
          </div>
        </div>

        <div class="custom-control custom-checkbox mb-3" v-if="$permissions().isSysAdmin || $permissions().isClientAdmin">
          <input id="userIsActive" name="userIsActive" class="custom-control-input" type="checkbox" v-model="user.isActive" />
          <label class="custom-control-label" for="userIsActive">Is Active</label>
        </div>

        <div class="custom-control custom-checkbox mb-3">
          <input id="emailOptOut" name="emailOptOut" class="custom-control-input" type="checkbox" v-model="user.emailOptOut" />
          <label class="custom-control-label" for="emailOptOut">Email Opt Out</label>
        </div>

        <user-roles-member :user="user" v-if="isCompanyUser === true"></user-roles-member>
        <user-roles-admin :user="user" v-if="isCompanyUser === false" ref="adminUserRoles"></user-roles-admin>
      </template>

    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-success">Save</button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";
import UserRolesMember from "@/views/configuration/components/user-roles-member.vue";
import UserRolesAdmin from "@/views/configuration/components/user-roles-admin.vue";

export default {
  name: 'user',
  components: { UserRolesMember, UserRolesAdmin },
  data: function () {
    return {
      isPageLoading: true,
      isEdit: false,
      user: {
        firstName: null,
        lastName: null,
        email: null,
        roleIds: [],
        companies: [],
        zones: [],
        emailOptOut: false
      }
    }
  },
  computed: {
    isCompanyUser () {
      return this.user.companies.length > 0;
    },
    ...mapGetters(["getRoles", "getUser", "getZones"])
  },
  mounted: function() {
    this.user = { ...this.getUser };
    this.isEdit = !!this.user.id;
    this.$validator.reset();

    this.isPageLoading = false;
  },
  methods: {
    isPasswordConfirmRequired() {
      if (!this.$refs.password) return false;
      if (this.isEdit === false) return true;

      return this.$refs.password.value.length > 0;
    },
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      if (this.$refs.adminUserRoles) {
        let _selectedZones = this.$refs.adminUserRoles.selectedZones;
        let _selectedDefaultZone = this.$refs.adminUserRoles.selectedDefaultZone;

        this.user.zones = _selectedZones.map(zone => {
          return { zoneId: zone, isDefault: zone === _selectedDefaultZone }
        });
      }

      this.saveUser({ data: this.user, isEdit: this.isEdit })
        .then(() => {
          Toast.success(this, "Successfully saved!");
          this.$bvModal.hide("bv-modal-user");
          this.$emit('save-action');
          this.closeModal();
        })
        .catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.closeModal();
        });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-user");
    },
    ...mapActions(["saveUser"])
  }
}
</script>
