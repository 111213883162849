<template>
  <fragment>

      <div class="custom-control custom-checkbox mb-3" v-if="$permissions().isSysAdmin || $permissions().isClientAdmin">
        <input id="userIsClientAdmin" name="userIsClientAdmin" class="custom-control-input" type="checkbox" v-model="user.isClientAdmin" />
        <label class="custom-control-label" for="userIsClientAdmin">Is Client Admin</label>
      </div>

      <transition name="slide-fade">
        <div class="form-group row" v-if="user.isClientAdmin">
          <legend class="col-form-label col-sm-3 pt-0">Assigned Zones</legend>
          <div class="col-sm-9">
            <div class="custom-control custom-checkbox" v-for="zone in getZones" :key="zone.id">
              <input :id="'assigned-zone-' + zone.id" type="checkbox" class="custom-control-input" :value="zone.id" v-model="selectedZones" />
              <label :for="'assigned-zone-' + zone.id" class="custom-control-label">{{ zone.name }}</label>
            </div>
            <input name="assignedZonesCount" type="hidden" class="form-control" :class="{ 'is-invalid': errors.has('assignedZonesCount') }"
              v-model="isAssignedZonesRequired" v-validate="'required:true'" data-vv-as="Assigned Zones" />
            <div class="invalid-feedback">Please assign at least one zone.</div>
          </div>
        </div>
      </transition>

      <transition name="slide-fade">
        <div class="form-group row" v-if="user.isClientAdmin">
          <legend class="col-form-label col-sm-3 pt-0">Default Zone</legend>
          <div class="col-sm-9">
            <select name="defaultZone" class="custom-select" :class="{ 'is-invalid': errors.has('defaultZone') }" v-model="selectedDefaultZone" v-validate="'required'">
              <option v-for="zone in defaultZoneCollection" :key="'default-zone-' + zone.id" :value="zone.id">{{ zone.name }}</option>
            </select>
            <div class="invalid-feedback">Please assign at least one zone.</div>
          </div>
        </div>
      </transition>

      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-3 pt-0">Roles</legend>
          <div class="col-sm-9">
            <div class="custom-control custom-checkbox" v-for="role in filteredRoles" :key="role.id">
              <input :id="'role-' + role.id" name="role" type="checkbox" class="custom-control-input" :value="role.id" v-model="user.roleIds" />
              <label :for="'role-' + role.id" class="custom-control-label">{{ role.name }}</label>
            </div>
          </div>
        </div>
        <input name="rolePermissionCount" type="hidden" class="form-control" :class="{ 'is-invalid': errors.has('rolePermissionCount') }"
          v-model="isPermissionsRequired" v-validate="'required:true'" data-vv-as="Permission" />
        <div class="invalid-feedback">Please select at least one role.</div>
      </fieldset>

  </fragment>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'user-roles-admin',
  inject: ["$validator"],
  props: {
    user: { type: Object, required: true }
  },
  data: function () {
    return {
      selectedZones: [],
      selectedDefaultZone: null
    }
  },
  computed: {
    defaultZoneCollection () {
      return this.getZones.filter(zone => this.selectedZones.indexOf(zone.id) > -1);
    },
    filteredRoles() {
      return this.getRoles.filter(role => role.isMembershipType === false);
    },
    isAssignedZonesRequired () {
      return this.user.isClientAdmin && this.selectedZones.length > 0;
    },
    isPermissionsRequired() {
      return this.user.isClientAdmin || this.user.roleIds.length > 0;
    },
    ...mapGetters(["getRoles", "getUser", "getZones"])
  },
  mounted () {
    this.selectedZones = this.user.zones.map(zone => zone.zoneId);
    let defaultZone = this.user.zones.find(zone => zone.isDefault);
    if (defaultZone) {
      this.selectedDefaultZone = defaultZone.zoneId;
    }
  }
}
</script>
