<template>
  <fragment>
    <hr />

    <div class="alert alert-warning" role="alert">
      Display Only! Please visit company page to edit permissions
    </div>

    <div v-for="(company, index) in user.companies" :key="'user-company-' + index">
      <div class="d-flex justify-content-between">
        <h4 class="mb-3"> {{ company.name }} </h4>

        <span v-if="$permissions().hasEditCompanyPermission">
          <router-link :to="'/company/edit/' + company.id" title="Go to company profile" class="btn btn-success mb-2">
            <i class="far fa-edit"></i>
            Edit Company Profile
          </router-link>
        </span>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th scope="col">Zone</th>
            <th scope="col">Membership Level</th>
            <th scope="col">Expiry Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(membership, index) in company.memberships" :key="'company-membership-' + index">
            <td>{{ membership.zone }}</td>
            <td>{{ membership.role }}</td>
            <td>{{ membership.expiryDate | datetime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </fragment>
</template>

<script>
export default {
  name: "user-roles-member",
  props: {
    user: { type: Object, required: true }
  }
};
</script>
